import { useCallback, useEffect, useState } from "react"
import queryString from "query-string"

// -- FUNCTIONS
const addParam = (current, newParam) =>
  current.includes("?") ? `${current}&${newParam}` : `?${newParam}`

// -- CONSTANTS
const endpoints = {
  abstract: "/api/abstract",
  preference: "/api/user/preference",
}

function useAPI() {
  const [bkpkReady, setBkpkReady] = useState(false)
  const [bkpkValue, setBkpkValue] = useState(null)

  useEffect(() => {
    if (window) {
      const searchString = window?.location?.search || ""
      const parsed = queryString.parse(searchString)
      const parsedBkpk = parsed?.bkpk || null

      if (parsedBkpk) {
        setBkpkReady(true)
      }

      setBkpkValue(parsedBkpk)
    }
  }, [])

  return {
    bkpkReady,
    getAbstract: useCallback(
      ({ edition, submissionId }) =>
        fetch(
          `${endpoints.abstract}/${edition}/${submissionId}?bkpk=${bkpkValue}`
        ),
      [bkpkValue]
    ),
    getAbstractsForBrowser: useCallback(
      ({ edition, qParams }) =>
        bkpkValue
          ? fetch(
              `${endpoints.abstract}/${edition}${addParam(
                qParams,
                `bkpk=${bkpkValue}`
              )}`
            )
          : null,
      [bkpkValue]
    ),
    getPaginatedAbstractsForBrowser: useCallback(
      ({ next }) => fetch(`${addParam(next, `bkpk=${bkpkValue}`)}`),
      [bkpkValue]
    ),
    getPreference: useCallback(
      ({ edition }) =>
        bkpkValue
          ? fetch(`${endpoints.preference}/${edition}?bkpk=${bkpkValue}`)
          : null,
      [bkpkValue]
    ),
    addToPreference: useCallback(
      ({ edition, submissionId }) => {
        return fetch(
          `${endpoints.preference}/${edition}/${submissionId}?bkpk=${bkpkValue}`,
          {
            method: "PUT",
          }
        )
      },
      [bkpkValue]
    ),
    removeFromPreference: useCallback(
      ({ edition, submissionId }) => {
        return fetch(
          `${endpoints.preference}/${edition}/${submissionId}?bkpk=${bkpkValue}`,
          {
            method: "DELETE",
          }
        )
      },
      [bkpkValue]
    ),
  }
}

export default useAPI
